.new-incident-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.new-incident-container .content {
    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-incident-container .content section {
    width: 100%;
    max-width: 380px;
}

.new-incident-container .content section h1 {
    margin: 64px 0 32px;
    font-size: 32px;
}

.new-incident-container .content section p {
    font-size: 10px;
    color: #737380;
    line-height: 32px;
}

.new-incident-container .content form {
    width: 100%;
    max-width: 450px;
}

.new-incident-container .content form input, .new-incident-container .content form textarea {
    margin-top: 8px;
}
