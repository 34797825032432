@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px 'Roboto', sans-serif;
    background-color: #f0f0f5;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea {
    font: 400 18px 'Roboto', sans-serif;
}

button {
    cursor: pointer;
}

form input {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
}

form textarea {
    width: 100%;
    resize: vertical;
    height: 60px;
    min-height: 140px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 16px 24px;
    line-height: 24px;
}

.button {
    width: 100%;
    height: 60px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.button:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.back-link {
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #41414d;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.back-link svg {
    margin-right: 8px;
}

.back-link:hover {
    opacity: 0.8;
}
.logon-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logon-container section.form {
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
}

.logon-container section.form form {
    margin-top: 100px;
}

.logon-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 32px;
}
.register-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.register-container .content {
    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.register-container .content section {
    width: 100%;
    max-width: 380px;
}

.register-container .content section h1 {
    margin: 64px 0 32px;
    font-size: 32px;
}

.register-container .content section p {
    font-size: 10px;
    color: #737380;
    line-height: 32px;
}

.register-container .content form {
    width: 100%;
    max-width: 450px;
}

.register-container .content form input {
    margin-top: 8px;
}

.register-container .content form .input-group {
    display: flex;
}

.register-container .content form .input-group input + input {
    margin-left: 8px;
}
.profile-container {
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.profile-container header {
    display: flex;
    align-items: center;
}

.profile-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.profile-container header img {
    height: 64px;
}

.profile-container header .button {
    width: 260px;
    margin-left: auto;
    margin-top: 0px;
}

.profile-container header button {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: 16px;
    transition: border-color 0.2s;
}

.profile-container header button:hover {
    border-color: #999;
}

.profile-container h1 {
    margin-top: 80px;
    margin-bottom: 24px;
}

.profile-container ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    list-style: none;
}

.profile-container ul li {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    position: relative;
}

.profile-container ul li button {
    position: absolute;
    right: 24px;
    top: 24px;
    border: 0;
    background: transparent;
    transition: opacity 0.2s;
}

.profile-container ul li button:hover {
    opacity: 0.8
}

.profile-container ul li strong {
    display: block;
    margin-bottom: 16px;
    color: #41414d;
}

.profile-container ul li p + strong {
    margin-top: 32px;
}

.profile-container ul li p {
    color: #737380;
    line-height: 21px;
    font-size: 16px;
}
.new-incident-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.new-incident-container .content {
    width: 100%;
    padding: 96px;
    background: #f0f0f5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-incident-container .content section {
    width: 100%;
    max-width: 380px;
}

.new-incident-container .content section h1 {
    margin: 64px 0 32px;
    font-size: 32px;
}

.new-incident-container .content section p {
    font-size: 10px;
    color: #737380;
    line-height: 32px;
}

.new-incident-container .content form {
    width: 100%;
    max-width: 450px;
}

.new-incident-container .content form input, .new-incident-container .content form textarea {
    margin-top: 8px;
}

